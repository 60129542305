import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Partners from "../components/Partners"

import { Outlet } from "react-router-dom";
import { ScrollRestoration } from "react-router-dom";

const Root = () => {
    return (
        <>
            <ScrollRestoration />
            <Header/>
            <main>
                <Outlet/>
                <Partners/>
            </main>
            <Footer/>
        </>
    )
};

export default Root;