import React, { useState } from 'react';
import LinkedInLogo from "../assets/linkedin-logo.svg";
import TwitterLogo from "../assets/twitter-logo.svg";
import GeobuzzMenuLogo from "../assets/logo-geobuzz-menu.svg";

import { NavLink } from 'react-router-dom';

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleMouseEnter = () => {
        setIsOpen(true);
      };
    
      const handleMouseLeave = () => {
        setIsOpen(false);
      };

    return (
        <>
            <div className="menu-1">
                <div className="menu-1-social-logo-container">
                    <a href="https://www.linkedin.com/company/geobuzz" target="_blank"><img src={LinkedInLogo} className="menu-1-social-logo" /></a>
                    <a href="https://geobuzz.nl/#GeoBuzz_Event" target="_blank"><img src={TwitterLogo} className="menu-1-social-logo" /></a>
                </div>
                <p><strong>Mail ons: info@geobuzz.nl</strong></p>
            </div>
            <div className="menu-2-container">
                <div>
                    <NavLink to=""><img src={GeobuzzMenuLogo} className="menu-2-logo" /></NavLink>
                </div>
                <div className="menu-2">
                    <NavLink to="/beurs" className="menu-2-link" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>Beurs</NavLink>
                    {isOpen && (
                        <div className="menu2__dropdown__beurs" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                            <NavLink to="/reserveren" onClick={toggleDropdown}>Reserveren</NavLink>
                            <NavLink to="/prijzen" onClick={toggleDropdown}>Prijzen</NavLink>
                        </div>
                    )}
                    <NavLink to="/programma" className="menu-2-link">Programma</NavLink>
                    <NavLink to="/deelname" className="menu-2-link">Deelname</NavLink>
                    <NavLink to="/contact" className="menu-2-link">Contact</NavLink>
                </div>
            </div>
        </>
    )
}

export default Header;