import React from "react";
import { Link } from "react-router-dom";

const WinkelCard = ({productObject, addToCart}) => {
    return (
        <div className="productCard">
            <div className="productCard__preview">
                <img/>
                <p className="productCard__prijs">€ {productObject.prijs},-</p>
            </div>
            <div className="productCard__content">
                <h3>{productObject.product}</h3>
                <p className="productCard__location">{productObject.beschrijving}</p>
                <div className="productCard__bottom">
                    <button className="productCard__link" onClick={() => addToCart(productObject)}>Toevoegen</button>
                </div>
            </div>
        </div>
    )
}

export default WinkelCard;