import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";


import Hero from "../components/Hero";
import LinkNextPresentation from "../helpers/LinkNextPresentation";
import LinkPrevPresentation from "../helpers/LinkPrevPresentation";

const PresentationSingle = (props) => {
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    }

    let { id } = useParams();
    const presentationData = props.data.find(element => element["Presentatie ID"] == id);

    return (
        <div className="container-presentationSingle">
            <Hero heroTitle={presentationData.titel}/>
            <LinkPrevPresentation id={id}/>
            <LinkNextPresentation id={id}/>
            <div className="single-content container-single-content">
                <p className="presentationSingle__beschrijving">{presentationData.beschrijving}</p>
                <h3 className="spreker">{presentationData.spreker}</h3>
                <p className="organisatie">{presentationData.organisatie}</p>
                <p>{presentationData.locatie}</p>
                <p className="presentationSingle__date">{presentationData.datum} | {presentationData.tijdstip}</p>
            </div>
        </div>
    );

};

export default PresentationSingle;