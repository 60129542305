import React, { useState } from "react";
import Hero from "../components/Hero";
import WinkelCard from "../components/WinkelCard";

const Winkel = (props) => {
    const [cart, setCart] = useState([]);
    const [contact, setContact] = useState({
        naam: "",
        email: "",
        organisatie: "",
        opmerking: ""
    })
    const [send, setSend] = useState(false);

    const addToCart = (product) => {
        setCart((prevCart) => {
            const existingProduct = prevCart.find(item => item.ID === product.ID);
            if (existingProduct) {
                return prevCart.map(item =>
                    item.ID === product.ID
                        ? { ...item, quantity: item.quantity + 1 }
                        : item
                );
            } else {
                return [...prevCart, { ...product, quantity: 1 }];
            }

        })
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setContact((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const sendCart = (e) => {
        e.preventDefault();
        const sendData = JSON.stringify({cart: cart, contact: contact})
        console.log(sendData);
        fetch("https://geobuzz.nl/api/cart", {
            method: "POST",
            headers: {
                "Content-type": "application/json"
            },
            body: sendData
          }).then((response) => {
            console.log("fetch call gedaan");
            console.log(response);
            if (response.ok) {
                console.log("response = ok")
                setCart([]);
                setContact({
                    naam: "",
                    email: "",
                    organisatie: "",
                    opmerking: ""
                });
                setSend(true);
            }
        });
    }

    return (
        <div className="winkel__container">
            <Hero heroTitle="Winkel" />
            <div className="winkel__header">
                <h2>Beurswinkel</h2>
                <p>Hieronder kunt u extra beursmaterialen bestellen. Wij zorgen ervoor dat alles voor u klaar staat. Heeft u andere wensen? Neem contact met ons op voor de mogelijkheden.<br />
                    U kunt de producten hieronder selecteren. Onder de producten staat de bestellijst.</p>
            </div>
            <div className="winkel__btn__cart">
            <a href="#bestellijst"><button>Bestellijst</button></a>
            </div>
            <div className="winkel__content-container">
                {
                    props.data.map(product => {
                        return (
                            <WinkelCard
                                key={product.ID}
                                productObject={product}
                                addToCart={addToCart}
                            />
                        )
                    })
                }
            </div>
            <div id="bestellijst" className="winkel__cart">
                <h2>Bestelling</h2>
                <div className="winkel__cart__order">
                    {cart.map(product => {
                        return (
                            <p>{product.quantity} x {product.product}</p>
                        )
                    })}
                </div>
                <form className="winkel__cart__form" onSubmit={sendCart}>
                    <label for="naam">Naam</label>
                    <input
                        type="text"
                        name="naam"
                        id="naam"
                        value={cart.naam}
                        onChange={handleInputChange}
                        required
                    />
                    <label for="email">E-mailadres</label>
                    <input
                        type="text"
                        name="email"
                        id="email"
                        value={cart.email}
                        onChange={handleInputChange}
                        required
                    />
                    <label for="organisatie">Organisatie</label>
                    <input
                        type="text"
                        name="organisatie"
                        id="organisatie"
                        value={cart.organisatie}
                        onChange={handleInputChange}
                        required
                    />
                    <label for="opmerking">Bericht</label>
                    <textarea
                        name="opmerking"
                        id="opmerking"
                        value={cart.opmerking}
                        onChange={handleInputChange}
                    />
                    <input id="cart__submit" type="submit"/>
                </form>
                {
                    send && (<p>Uw bestelling is verzonden, bedankt. We nemen zo snel mogelijk contact met u op.</p>)
                }
            </div>
        </div>
    );
}

export default Winkel;