import React, { useState } from "react";
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

// import logo from './logo.svg';
// Componenten
import Root from "./components/Root";
import Home from "./pages/Home";
import Beurs from "./pages/Beurs";
import Plattegrond from "./pages/Plattegrond";
import Prijzen from "./pages/Prijzen";
import Reserveren from "./pages/Reserveren";
import Programma from "./pages/Programma";
import Deelname from "./pages/Deelname";
import Contact from "./pages/Contact";
import PresentationSingle from "./pages/PresentationSingle";
import Handboek from "./pages/Handboek";
import Winkel from "./pages/Winkel";

//data
import Data from "./data/presentations-25-11.json";
import WinkelData from "./data/winkel-producten.json";


const router = createBrowserRouter(createRoutesFromElements(
  <Route path="/" element={ <Root /> }>
    <Route index element={ <Home /> }/>
    <Route path="beurs" element={ <Beurs />}>
      <Route path="plattegrond" element={ <Plattegrond /> }/>
    </Route>
    <Route path="programma" element={ <Programma data={Data}/>} />
    <Route path="/programma/:id" element ={ <PresentationSingle data={Data}/> }/>
    <Route path="deelname" element={ <Deelname />} />
    <Route path="contact" element={ <Contact />} />
    <Route path="beurs-handboek" element={ <Handboek />}/>
    <Route path="prijzen" element={ <Prijzen /> }/>
    <Route path="winkel" element={ <Winkel data={WinkelData}/>} />
    <Route path="reserveren" element={ <Reserveren /> }/>
  </Route>
));

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
